
import Vue from 'vue'
import { addRow, checkCondition, getOptions, getImage, getSubformFieldPath, isNotNested,
         parseData, parseForm, removeRow } from '../../mixins/formData'

export default Vue.extend({
  name: 'ProjektForm',

  data: () => {
    const formData: any = {}
    const subformData: any = {}
    return {
      autosave: (null as any),
      errorMsg: '',
      errors: [],
      fachbereich: 0,
      formData,
      formObj: {},
      layouts: {},
      initCounter: 0,
      isLoading: true,
      subformData,
      typologie: 0,
    }
  },

  computed: {
    appInfo () {
      return this.$store.state.appInfo
    },
    awardConf () {
      return this.$store.state.currentAwardConf
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
    currentAward () {
      return this.$store.state.currentAward
    },
    currentPart () {
      return this.$route.params.part ||this.$store.state.currentAwardConf.forms.user_project_save.parts[0].key
    },
    formParts () {
      if (!this.$store.state.currentAwardConf) return null
      return this.$store.state.currentAwardConf.forms.user_project_save.parts
    },
    formTitle () {
      if (this.formData['part2/titel'] && this.formData['part2/titel'].length > 0) {
        return this.formData['part2/titel']
      }
      return this.formData['part1/titel']
    },
    id () {
      return this.$route.params.id
    },
    projectCategories () {
      return this.$store.state.appInfo ? this.$store.state.appInfo.meta.projectCategories : null
    },
    triggerSaveForm () {
      return this.$store.state.triggerSaveForm
    },
  },

  watch: {
    // formData: {
    //   handler(newData) {
    //     console.log('formData -> newData', newData);
    //   },
    //   deep: true
    // },
    triggerSaveForm (val) {
      // console.log('WATCH triggerSaveForm: ', val);
      if (val) {
        this.saveProject(true)
      }
    },
  },

  methods: {
    addRow (field: any, index: number) {
      const subformData = addRow(field, index, this.subformData)
      this.subformData = subformData
    },

    addRowMany (field: any, index: number) {
      const path = this.currentPart + '/' + field.name
      const formData = addRow(field, index, Object.assign({}, this.formData), path)
      this.formData = Object.assign({}, this.formData, formData)
    },

    addRowMany2 (field: any, f: any, i: number, j: number) {
      const path = this.currentPart + '/' + field.name + '[' + i + '].' + f.name
      const formData = addRow(field, j, Object.assign({}, this.formData), path)
      this.formData = Object.assign({}, this.formData, formData)
    },

    initProject() {
      this.$store
        .dispatch('getProjectItem', this.$route.params.id)
        .then((project) => {
          const { awardType, year } = this.currentAward
          if (project.projectDetails) {
            const pd = project.projectDetails.filter((o: any) => o.awardType == awardType && o.year == year)
            if (pd && pd[0]) {
              const pd0 = Object.entries(pd[0])
              pd0.forEach(o => {
                if (typeof o[1] !== 'undefined' && typeof project[o[0]] === 'undefined') {
                  project[o[0]] = o[1]
                }
              })
            }
          }

          const { formData, subformData, layouts, reloadTriggers } = this.parseData(this.formParts, project, this.formData)
          this.$store.commit('setReloadTriggers', reloadTriggers)
          this.formObj = project
          this.formData = Object.assign({}, this.formData, formData)
          this.subformData = Object.assign({}, this.subformData, subformData)
          this.layouts = Object.assign({}, this.layouts, layouts)
          this.isLoading = false
          this.$store.commit('triggerSaveForm', false)
          this.initCounter++
        }).catch((err: Error) => {
          console.log('err: ', err)
        })
    },

    lastStep () {
      if (!this.formParts) return false
      return this.currentPart === this.formParts[this.formParts.length - 1].key
    },

    remoteFunction (opt: any) {
      this.$store.commit('updateRemoteFunctionValue', opt);
      if (this.subformData.remoteFunction && this.subformData.remoteFunction[opt.name]) {
        let obj = this.subformData.remoteFunction[opt.name]
        obj[opt.path] = opt.value
        // console.log('remoteFunction obj: ', obj);
        const { awardType, year } = this.currentAward
        const formName = 'user_project_save'
        let data: any = {
          data: obj,
          formName,
          awardType,
          year,
          name: opt.name,
          id: this.id,
          part: this.currentPart,
        }
        this.$store.dispatch('postRemoteFunction', data).then((res: any) => {
          // console.log('postRemoteFunction RESPONSE: ', res)
          if (res && res.output) {
            for (let k in res.output) {
              this.formData[this.currentPart + '/' + k] = res.output[k]
            }
            this.formData = Object.assign({}, this.formData)
          }
        })
      }
    },

    removeRow (field: any, index: number) {
      const { formData, subformData } = removeRow(field, index, this.formData, this.subformData)
      this.formData = formData
      this.subformData = subformData
    },

    removeRowMany (field: any, index: number) {
      const path = this.currentPart + '/' + field.name
      const { formData } = removeRow(field, index, Object.assign({}, this.formData), {}, path)
      this.formData = formData
    },

    removeRowMany2 (field: any, f: any, i: number, j: number) {
      const path = this.currentPart + '/' + field.name + '[' + i + '].' + f.name
      const { formData } = removeRow(field, j, Object.assign({}, this.formData), {}, path)
      this.formData = formData
    },

    replaceN (str: string, num: any) {
      return str.replace('%n%', num)
    },

    async saveProject(silent: boolean) {
      silent = silent === true
      if (!silent) this.$store.commit('setProcessing', true)
      const { awardType, year } = this.currentAward
      const formName = 'user_project_save'
      let formData = this.parseForm(this.formParts, this.formData)[this.currentPart]
      let proj: any = {
        formData,
        formName,
        awardType,
        year,
        id: this.id,
        part: this.currentPart,
        silent,
      }

      if (!silent) this.errors = [];
      this.$store.dispatch('saveProject', proj).then((project) => {
        this.formObj = project
        this.initProject()
        if (!silent) {
          this.$store.commit('setAlert', {
            type: 'info',
            message: 'Schritt ' + this.currentPart.replace('part', '') + ' wurde erfolgreich gespeichert.'
          })

          if (!this.lastStep()) {
            const stepNum = parseInt(this.currentPart.substr(this.currentPart.length - 1), 10)
            const part = this.currentPart.replace(stepNum, stepNum + 1)
            this.$router.push({name: 'projekt', params: { id: project.id, part }})
          } else {
            this.$router.push('/anmelden/index')
          }
          this.$store.commit('setProcessing', false)
          window.scrollTo({top: 0, behavior: 'smooth'})
        }
      }).catch((err: Error) => {
        if (!silent && err.message.indexOf('errorObject') >= 0) {
          const errors = JSON.parse(err.message)
          this.errors = errors.errorObject
          this.$store.commit('setAlert', {
            type: 'error',
            message: 'Ihre Daten wurden gespeichert. Für eine erfolgreiche Einreichung werden noch Daten in den Pflichtfeldern benötigt.',
            cb: () => {
              const errors = document.querySelectorAll('.form-field .error')
              let el: any = errors[0]
              if (el) {
                let top = 0;
                while (el) {
                  top += el.offsetTop
                  el = el.offsetParent
                }
                window.scrollTo({top: top - 20, behavior: 'smooth'})
              }
            }
          })
        } else {
          console.error('error saving project')
        }
        this.$store.commit('setProcessing', false)
        this.$store.commit('triggerSaveForm', false)
        this.initCounter++
      })
      return false
    },

    checkCondition,
    getImage,
    getOptions,
    getSubformFieldPath,
    isNotNested,
    parseData,
    parseForm,
  },

  mounted () {
    this.initProject()

    this.autosave = setInterval(() => {
      this.saveProject(true)
    }, 2 * 60 * 1000) // save every 2 min
  },

  destroyed () {
    clearInterval(this.autosave)
  },

})
